import React, { useMemo } from "react";
import { Card, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import TableLoadingOverlay from "../../../components/MaterialTableLoadingOverlay";
import PageNotFound from "../../404";
import BarChart from "./BarChart";
import { ColumnChart } from "./ColumnChart";
import DataGrid from "./DataGrid";
import OfficesHighConcentration from "./OfficesHighConcentration";
import PieChart from "./PieChart";
import QaAuditChecklist from "./QaAuditChecklist";
import ReportDownloads from "./ReportDownloads";
import SalesVettingTable from "./SalesVettingTable";
import { ScatterGraph } from "./ScatterGraph";
import Table from "./Table";
import { TableWithToggle } from "./TableWithToggle";

function DataTitle({ id, label }) {
  return (
    <Typography data-section variant="h3" id={id} style={{ textAlign: "center", fontSize: "22px", fontWeight: "500", fontFamily: "IBM Plex Sans,sans-serif", margin: "0.5em" }}>
      {label}
    </Typography>
  );
}
const useStyles = makeStyles(() => ({
  gridCards: {
    margin: "5px 8px",
  },
}));

export default function Renderer({ currentSheetItems, currentSheetData, currentView, loading }) {
  const memoizedLoadingOverlay = useMemo(() => <TableLoadingOverlay />, []);
  const classes = useStyles();
  return (
    <Card className={classes.gridCards} style={{ padding: "15px", minHeight: "400px" }}>
      {!loading
        ? (
          <Grid container spacing={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {currentSheetItems?.map((item) => {
              switch (item.componentType) {
              case "table":
                return item.id !== "qa_audit_checklist_item" ? (
                  <Grid item xs={item.gridSize} key={item.id}>
                    <DataTitle id={item.id} label={item.label} />
                    <Table inputData={currentSheetData[item.dataKey]} style={{ minHeight: "100vh", width: "100%" }} formattingOptions={item.formattingOptions} />
                  </Grid>
                ) : (
                  <Grid item xs={item.gridSize} style={{ justifyContent: "center" }} key={item.id}>
                    <QaAuditChecklist inputData={currentSheetData[item.dataKey]} />
                  </Grid>
                );

              case "tableWithToggle":
                return (
                  <Grid item xs={item.gridSize} key={item.id}>
                    <DataTitle id={item.id} label={item.label} />
                    <TableWithToggle inputData={currentSheetData[item.dataKey]} formattingOptions={item.formattingOptions} />
                  </Grid>
                );
              case "officesHighConcentration":
                return (
                  <Grid item xs={item.gridSize} key={item.id}>
                    <DataTitle id={item.id} label={item.label} />
                    <OfficesHighConcentration />
                  </Grid>
                );
              case "salesVettingTable":
                return (
                  <Grid item xs={item.gridSize} key={item.id}>
                    <SalesVettingTable id={item.id} label={item.label} columnHeader={item.dataKey} currentView={currentView} inputData={currentSheetData?.[item.dataKey]} style={{ minHeight: "100vh", width: "100%" }} formattingOptions={item.formattingOptions} />
                  </Grid>
                );
              case "pieGraph":
                return (
                  <Grid item xs={item.gridSize} key={item.id}>
                    <DataTitle id={item.id} label={item.label} />
                    <PieChart
                      hidetitle
                      title={item.label}
                      inputData={currentSheetData?.[item.dataKey]}
                      legendName="Users"
                    />
                  </Grid>
                );
              case "barChart":
                return (
                  <Grid item xs={item.gridSize} key={item.id}>
                    <DataTitle id={item.id} label={item.label} />
                    <BarChart
                      hidetitle
                      title={item.label}
                      inputData={currentSheetData?.[item.dataKey]?.seriesData}
                      xAxisTitle=""
                      xAxisCategories={currentSheetData?.[item.dataKey]?.categories}
                      yAxisTitle="Number of Users"
                    />
                  </Grid>
                );
              case "dataGrid":
                return (
                  <Grid item xs={item.gridSize} key={item.id}>
                    <DataTitle id={item.id} label={item.label} />
                    <DataGrid inputData={currentSheetData?.[item.dataKey]} />
                  </Grid>
                );
              case "scatterGraph":
                return (
                  <Grid item xs={item.gridSize} key={item.id}>
                    <DataTitle id={item.id} label={item.label} />
                    <ScatterGraph hidetitle title={item.label} inputData={currentSheetData?.[item.dataKey]} xAxisTitle={item.xAxisTitle} yAxisTitle={item.yAxisTitle} />
                  </Grid>
                );
              case "columnChart":
                return (
                  <Grid item xs={item.gridSize} key={item.id}>
                    <DataTitle id={item.id} label={item.label} />
                    <ColumnChart hidetitle title={item.label} inputData={currentSheetData?.[item.dataKey]} isStacked={item?.isStacked} yAxisTitle={item.yAxisTitle} />
                  </Grid>
                );
              case "folders":
                return (
                  <Grid item xs={item.gridSize} key={item.id}>
                    <DataTitle id={item.id} label={item.label} />
                    <ReportDownloads />
                  </Grid>
                );
              default:
                return (
                  <PageNotFound />
                );
              }
            })}
          </Grid>
        )
        : <div style={{ margin: "15% auto" }}>{memoizedLoadingOverlay}</div>}

    </Card>
  );
}
