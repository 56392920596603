import React, { useState, useEffect } from "react";
import {
  Grid,
  Tabs,
  Tab,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import useSWR from "swr";
import usePageTitle from "../../../hooks/usePageTitle";

import { fetcher } from "../../../utils/swr";
import Bundles from "./Bundles";
import ClientBundleAssignment from "./ClientBundleAssignment";

export default function ApiAccessControl() {
  usePageTitle("API Access Control");
  const [selectedTab, setSelectedTab] = useState("bundles");
  const location = useLocation();

  const { data: bundles, error, isLoading } = useSWR(
    "lms/api/v1/misight/accessbundles",
    fetcher,
  );

  useEffect(() => {
    (location.state
    && location.state.selectedTab) && setSelectedTab(location.state.selectedTab);
  }, [location.state]);

  return (
    <Grid container spacing={3}>
      <Tabs
        value={selectedTab}
        onChange={(e, tabIndex) => { setSelectedTab(tabIndex); }}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="bundles" label="Bundles" />
        <Tab value="clients" label="Client Bundle Assignment" />
      </Tabs>
      {selectedTab === "bundles" ? <Bundles bundles={bundles} error={error} isLoading={isLoading} /> : <ClientBundleAssignment bundles={bundles} error={error} />}
    </Grid>
  );
}
