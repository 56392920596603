import React from "react";
import { Button, Card, CardContent, Typography, useTheme } from "@mui/material";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { getStyle } from "./StatusColor";

export default function ProductOverview({ allClientsData }) {
  const theme = useTheme();

  const annAllClientsTask = {
    reporting_ann_standard_report_discovery: { label: "Standard Report Status" },
    preprocessing_ann_populate_sales_totals_all_clients_discovery: { label: "Populate Totals Status (DD)" },
    preprocessing_dd_sync: { label: "DD Sync Status" },
    preprocessing_statham_ann: { label: "MISight Refresh" },
  };

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      {allClientsData.map((card) => (
        <Card
          key={card.task_type}
          style={{

            fontSize: 15,
            marginTop: 10,
            marginBottom: 20,
            marginRight: 10,
            marginLeft: 10,
            backgroundColor: getStyle(card.task_status, theme).backgroundColor,
            borderRadius: "10px",
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.75)",
          }}
        >
          <Button
            size="small"
            variant="text"
            component={Link}
            // eslint-disable-next-line no-nested-ternary
            to={`/details/${card.task_id ? card.workflow_id ? `${card.workflow_id}/${card.task_id}` : card.task_id : ""}`}
            target="_blank"
            style={{
              width: "300px",
              height: "100px",
            }}
            disabled={!card.updated_at}
          >
            <CardContent style={{ color: "white" }}>
              <Typography component="div" align="center">
                {annAllClientsTask[card.task_type]?.label}
              </Typography>
              <Typography variant="overline" component="div" align="center">
                {`Last run: ${card.updated_at ? moment(moment.parseZone(card.updated_at).local()).format("MMM Do YYYY, h:mm:ss a") : "NA"}`}
              </Typography>
            </CardContent>
          </Button>
        </Card>
      ))}
    </div>
  );
}
