import React, { useMemo, useState } from "react";
import {
  VerticalSplit as ClientRenamesIcon,
  TransformSharp as TransformIcon,
  ContactMail as ClientProfileIcon,
} from "@mui/icons-material";
import { Box, Grid, Tab, Tabs } from "@mui/material";

import { useSelector } from "react-redux";
import usePageTitle from "../../../hooks/usePageTitle";
import AnnuityFirmNameChangeProfilePage from "../../annuity_firms";
import PreprocessingTransform from "../../preprocessing_transforms";
import ClientProfiles from "./ClientProfiles";

export default function LmsClientsDD() {
  usePageTitle("LMS Clients");
  const environment = useSelector((state) => state.application.environment);
  const [selectedTab, setSelectedTab] = useState("profile");

  const tabContents = useMemo(() => {
    if (selectedTab === "profile") {
      return <ClientProfiles />;
    }
    if (selectedTab === "preprocessing") {
      return <PreprocessingTransform />;
    }
    return <AnnuityFirmNameChangeProfilePage />;
  }, [selectedTab]);

  return (
    <Grid container spacing={2}>
      <Box style={{ width: "100%" }}>
        <Tabs
          value={selectedTab}
          onChange={(e, tabIndex) => { setSelectedTab(tabIndex); }}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="profile" label="Discovery Data Client Profile" icon={<ClientProfileIcon />} iconPosition="end" />
          <Tab value="preprocessing" label="Preprocessing Transform" icon={<TransformIcon />} iconPosition="end" />
          <Tab value="name" label="Annuity Firm Name Change" icon={<ClientRenamesIcon />} iconPosition="end" />
        </Tabs>
      </Box>
      <Box style={{ width: "100%", marginLeft: 10 }}>
        {tabContents}
      </Box>
    </Grid>
  );
}
