import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

export default function ProfileNameColumn({ rowData }) {
  return (
    <Button
      variant="outlined"
      component={Link}
      to={`/client-profile/details/${rowData.client_profile_id}/study/${rowData.study}`}
      style={{ minWidth: 400 }}
    >
      {rowData.client_profile_short_name}
    </Button>
  );
}
