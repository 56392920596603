// FIXME: this file uses a lot of non-recommended syntax and forces lint exclusions
/* eslint-disable no-await-in-loop */
import axios from "axios";
import { useDispatch } from "react-redux";
import { fetching, fetchingDone } from "../state";
import { API } from "../utils/api";
import { uploadFileToS3 } from "../utils/s3";
import { preWarmTask } from "../views/tasks/create_wizard/constants";

const multipartCap = 1024 * 1024 * 20; // 20MB
// We want not too many requests but also not too few, could use file size but easier to adjust like this
const defaultChunkSize = 1024 * 1024 * 20; // 20MB
const maxChunks = 200;

function getChunksAndSize(fileSize) {
  const chunks = Math.ceil(fileSize / defaultChunkSize, defaultChunkSize);
  if (chunks < maxChunks) {
    return [chunks, defaultChunkSize];
  }
  const newChunkSize = Math.ceil(fileSize / maxChunks);
  return [maxChunks, newChunkSize];
}

function chunkFile(fileObj) {
  const fileparts = [];
  const [chunks, chunkSize] = getChunksAndSize(fileObj.size);
  let chunk = 0;
  while (chunk < chunks) {
    const offset = chunk * chunkSize;
    fileparts.push({ filePart: fileObj.slice(offset, offset + chunkSize, null) }); // file type NULL otherwise will be blocked by S3 CORS!
    chunk += 1;
  }
  return fileparts;
}

async function createTask(payload, setSubmitMessage) {
  return API.request({
    url: "/tasks",
    method: "POST",
    data: payload,
  }).then(
    (r) => {
      if (!r.data.taskId) {
        setSubmitMessage("Task could not be created", "error");
      } else {
        setSubmitMessage("Task created and saved!", "success");
      }
      return r.data.taskId;
    },
    (e) => {
      setSubmitMessage("Task could not be created", "error");
      console.error(e);
      return null;
    },
  );
}

async function createQueueTask(taskId) {
  return API.request({
    url: `/tasks-queue/${taskId}`,
    method: "POST",
  });
}
async function saveTask(taskId, payload, setSubmitMessage) {
  await API.request({
    url: `/tasks/${taskId}/config`,
    method: "POST",
    data: payload,
  }).then(
    () => setSubmitMessage("Config Saved", "success"),
    () => setSubmitMessage("Task update failure", "error"),
  );
}

async function run(taskId) {
  await API.request({
    url: `/tasks/${taskId}/publish`,
    method: "POST",
  });
}

function uploadFile(taskId, workflowId, csv, setSubmitMessage) {
  setSubmitMessage("fetching s3 location...");
  return new Promise((resolve, reject) => {
    // request presigned post
    let payload = {};
    if (workflowId) {
      payload = { fileName: csv.name, workflowId };
    } else {
      payload = { fileName: csv.name };
    }
    API.request({
      url: `/tasks/${taskId}/initiate-upload`,
      method: "POST",
      data: payload,
    }).then(
      (s3UploadInfo) => {
        setSubmitMessage("pushing to s3...");
        // upload file and update task
        uploadFileToS3(
          csv,
          s3UploadInfo.data,
          (progress) => setSubmitMessage(`Uploading: ${Math.round(progress)}%`),
          () => resolve(s3UploadInfo.data.fields.key),
        );
      },
      () => reject(),
    );
  });
}

async function initiateMultipartUpload(taskId, workflowId, fileName) {
  let payload = {};
  if (workflowId) {
    payload = { file_name: fileName, workflowId };
  } else {
    payload = { file_name: fileName };
  }
  const response = await API.request({
    url: `/tasks/${taskId}/uploads`,
    method: "POST",
    data: payload,
  });
  return response.data.upload_id;
}

async function uploadFileParts(fileParts, uploadId, taskId, workflowId, fileName, setSubmitMessage) {
  const filePartsWithUrls = [];
  const presignedRequestCreators = [];
  fileParts.forEach((filePart, index) => {
    let payload = {};
    if (workflowId) {
      payload = { file_name: fileName, part_number: index + 1, workflowId };
    } else {
      payload = { file_name: fileName, part_number: index + 1 };
    }
    presignedRequestCreators.push(API.request({
      url: `/tasks/${taskId}/uploads/${uploadId}/url`,
      method: "POST",
      data: payload,
    }).then((response) => filePartsWithUrls.push({
      ...filePart,
      part_number: index + 1,
      presignedUrl: response.data.url,
    })));
  });
  await Promise.all(presignedRequestCreators)
    .then(() => {
      setSubmitMessage("Received presigned URLs...", "success");
    })
    .catch((e) => {
      setSubmitMessage(`${e}`);
      console.log(e);
    });

  const etags = {};
  const presignedUploadRequests = [];

  let uploadedCount = 0;
  function progress(count) {
    const percent = (count / presignedUploadRequests.length) * 100;
    setSubmitMessage(`Uploading: ${percent.toFixed(0)}%`);
  }

  filePartsWithUrls.forEach((filePart) => {
    presignedUploadRequests.push(
      axios.put(
        filePart.presignedUrl,
        filePart.filePart,
        {
          transformRequest: [(data, headers) => {
            // -- IMPORTANT --
            // This is a workaround for a bug in newer axios > 0.27 where it sets the Content-Type header automatically
            // This is not allowed for presigned URLs, so we delete it here
            // Nowhere is it mentioned in the axios documentation and manually setting the headers { headers: { "Content-Type": null } } still gets overridden
            // This at least seems to work.
            headers.setContentType(null);
            return data;
          }],
        },
      ).then((response) => {
        progress(uploadedCount += 1);
        etags[filePart.part_number] = response.headers.etag;
      }),
      // TODO: If this fails abort the upload and display an error
    );
  });

  await Promise.all(presignedUploadRequests)
    .catch((e) => {
      setSubmitMessage(`${e}`, "error");
      console.log(e);
    });

  return etags;
}

async function completeMultipartUpload(taskId, workflowId, uploadId, fileName, etags, setSubmitMessage) {
  let payload = {};
  if (workflowId) {
    payload = { file_name: fileName, parts: etags, workflowId };
  } else {
    payload = { file_name: fileName, parts: etags };
  }
  return API.request({
    url: `/tasks/${taskId}/uploads/${uploadId}`,
    method: "POST",
    data: payload,
  }).then(
    (response) => {
      setSubmitMessage("Multipart Upload Complete", "success");
      return response.data.key;
    },
    () => setSubmitMessage("Multipart Upload Failed", "error"),
  );
}

async function handleRunTask(taskId, redirectToDetails, isPreWarmTask, setSubmitMessage) {
  try {
    await run(taskId);
    setSubmitMessage("Running!", "success");
    if (redirectToDetails) {
      window.location.href = `/details/${taskId}`;
    } else if (isPreWarmTask) {
      window.location.href = "/pre-warm";
    } else {
      window.location.href = "/status";
    }
  } catch (e) {
    setSubmitMessage("Could not run task", "error");
    console.error(e);
  }
}

async function handleQueueTask(taskId, redirectToDetails, setSubmitMessage) {
  try {
    await createQueueTask(taskId);
    setSubmitMessage("Pushed to Queue!", "success");
    window.location.href = redirectToDetails ? `/details/${taskId}` : "/status";
  } catch (e) {
    setSubmitMessage("Failed while pushing to Queue", "error");
    console.error(e);
  }
}

async function handleSaveTask(taskId, fullTaskPayload, setSubmitMessage) {
  await saveTask(taskId, fullTaskPayload, setSubmitMessage);
}

export default function useSubmitTaskHandler({
  id,
  setTaskId,
  client,
  studyType,
  taskType,
  taskConfig,
  title,
  csv,
  setSubmitMessage,
  redirectToDetails = false,
  queuedTask,
  setLoading = () => { }, // Optional parameter
  setShowDialog = () => { },
}) {
  const dispatch = useDispatch();
  const isPreWarmTask = taskType === preWarmTask;
  // const workflowId = useSelector(state => state.application.workflowId);
  return async function submitHandler(runTask, event, payload = taskConfig) {
    setSubmitMessage("fetching task id...");
    setLoading(true);
    dispatch(fetching({ element: "taskAPI" }));
    let taskId = id;
    const taskPayload = { ...payload };
    Object.keys(taskPayload).forEach(key => {
      if (taskPayload[key] === "") {
        delete taskPayload[key];
      }
    });
    const workflowName = localStorage.getItem("workflowName") === "null" ? null : localStorage.getItem("workflowName");
    const cloneWorkflowName = localStorage.getItem("cloneWorkflowName") === "null" ? null : localStorage.getItem("cloneWorkflowName");
    const workflowId = localStorage.getItem("workflowId") === "null" ? null : localStorage.getItem("workflowId");
    localStorage.removeItem("workflowName");
    localStorage.removeItem("cloneWorkflowName");
    let fullTaskPayload = {};
    fullTaskPayload = {
      taskType,
      client,
      studyType,
      taskPayload,
      title,
      workflowName,
    };
    // Set taskId by creating the task, otherwise save config
    if (!taskId) {
      // initialize task
      try {
        taskId = await createTask(fullTaskPayload, setSubmitMessage);
        setTaskId(taskId);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    } else {
      // resave config
      await saveTask(taskId, fullTaskPayload, setSubmitMessage);
    }
    if (!taskId) return;
    if (csv && Object.keys(csv).length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(csv)) {
        const currentFile = csv[key];
        if (!currentFile) {
          // eslint-disable-next-line no-continue
          continue;
        }
        // Disabling big files for now
        if (currentFile.size >= multipartCap) {
          try {
            setSubmitMessage("Initiating multipart upload ...");
            const fileParts = chunkFile(currentFile);
            const uploadID = await initiateMultipartUpload(taskId, workflowId, currentFile.name);
            const etags = await uploadFileParts(fileParts, uploadID, taskId, workflowId, currentFile.name, setSubmitMessage);
            // Fail completely if we're missing an etag, we really don't want to silently lose data
            if (fileParts.length !== Object.values(etags).length) {
              setSubmitMessage("Failed, missing a file part, task aborted.", "error");
              window.location.href = "/create";
            }
            const attachmentKey = await completeMultipartUpload(taskId, workflowId, uploadID, currentFile.name, etags, setSubmitMessage);
            if (!attachmentKey) {
              setSubmitMessage("Multipart upload failed, task aborted.", "error");
              window.location.href = "/create";
            }
            fullTaskPayload.taskPayload[key] = attachmentKey;
          } catch (e) {
            setLoading(false);
            console.error(e);
            window.location.href = "/create";
          }
        } else {
          let attachment;
          // Get uploaded s3 key location
          try {
            // for loops actually support await
            attachment = await uploadFile(taskId, workflowId, currentFile, setSubmitMessage);
            console.log(attachment);
          } catch (e) {
            setSubmitMessage(`Could not upload file: ${key}`, "error");
            setLoading(false);
            console.error(e);
            window.location.href = "/create";
          }
          // Attaching it to payload based on provided key
          setSubmitMessage(`Upload complete for ${key} - modifying task`);
          fullTaskPayload.taskPayload[key] = attachment;
        }
      }
      await saveTask(taskId, fullTaskPayload, setSubmitMessage);
      setLoading(false);
    }

    if (runTask && !queuedTask) {
      await handleRunTask(taskId, redirectToDetails, isPreWarmTask, setSubmitMessage);
    } else {
      if (workflowName === null && cloneWorkflowName === null) {
        if (queuedTask) {
          if (runTask) {
            await handleQueueTask(taskId, redirectToDetails, setSubmitMessage);
          } else {
            await handleSaveTask(taskId, fullTaskPayload, setSubmitMessage);
          }
          window.location.href = "/status";
        }
      } else if (workflowName !== null) {
        await handleSaveTask(taskId, fullTaskPayload, setSubmitMessage);
        window.location.href = "/create";
      } else {
        if (queuedTask) {
          await handleSaveTask(taskId, fullTaskPayload, setSubmitMessage);
        }
        setShowDialog(false);
        window.location.href = `/workflows/edit/${cloneWorkflowName}`;
      }

      dispatch(fetchingDone({ element: "taskAPI" }));

      if (runTask) {
        window.location.href = isPreWarmTask ? "/pre-warm" : "/status";
      } else {
        if (cloneWorkflowName !== null) return;
        if (workflowName !== null) {
          window.location.href = "/create";
          return;
        }
        window.location.href = isPreWarmTask ? "/pre-warm" : "/status";
      }
    }
  };
}
