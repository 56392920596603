import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../../../utils/api";
import Table from "./Table";

function OfficesHighConcentration() {
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowCount, setRowCount] = useState();
  const productionCycle = useSelector((state) => state.formOptions.productionCycle);
  const components = {
    Loading: (params) => {
      if (params.value !== undefined) {
        return params.value;
      }
      return (
        "Loading...."
      );
    },
  };

  const onGridReady = useCallback((params) => {
    const dataSource = {
      getRows: (props) => {
        API.request({
          url: "qa/offices-high-concentration",
          method: "GET",
          params: {
            offset: props.startRow || 0,
            limit: props.endRow - props.startRow || 200,
            filterModel: JSON.stringify(props.filterModel) || JSON.stringify({}),
            productionCycle: productionCycle[1],
          },
        }).then((response) => {
          // Disable multiple conditions with AND/OR operator for this table
          const columnsWithFilterOptions = response.data?.offices_with_high_client_concentration_live?.columns.map(
            (column) => ({
              ...column,
              filterParams: { maxNumConditions: 1 },
            }),
          );
          setColumnDefs(columnsWithFilterOptions);
          setRowCount(response.data?.offices_with_high_client_concentration_live?.row_count);
          let lastRow = -1;
          if (props.endRow >= response.data?.offices_with_high_client_concentration_live?.row_count) {
            lastRow = response.data?.offices_with_high_client_concentration_live?.data.length === 0
              ? 0 : response.data?.offices_with_high_client_concentration_live?.row_count;
          }
          props.successCallback(response.data?.offices_with_high_client_concentration_live?.data, lastRow);
        });
      },
    };
    params.api.setDatasource(dataSource);
  }, [productionCycle]);

  return (
    <Table
      columnDefs={columnDefs}
      rowBuffer={200}
      rowModelType="infinite"
      cacheBlockSize={200}
      cacheOverflowSize={2}
      maxConcurrentDatasourceRequests={1}
      infiniteInitialRowCount={1000}
      maxBlocksInCache={10}
      onGridReady={onGridReady}
      domLayout={rowCount < 20 ? "autoHeight" : "normal"}
      components={components}
    />
  );
}

export default OfficesHighConcentration;
