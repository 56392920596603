import React, { useEffect, useMemo, useState } from "react";
import { Box, Card, CardContent, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import Spinner from "../../../../../components/Spinner";
import usePageTitle from "../../../../../hooks/usePageTitle";
import { API } from "../../../../../utils/api";
import PageNotFound from "../../../../404";
import TaskEdit from "../../../../tasks/create_wizard/TaskEdit";
import TaskStep1 from "../../../../tasks/create_wizard/TaskStep1";
import TaskStep2 from "../../../../tasks/create_wizard/TaskStep2";
import { preWarmTask } from "../../../../tasks/create_wizard/constants";

export default function TaskCreateWizardPreWarm({ setSubmitMessage, taskId, setShowDialog }) {
  // --- Task Update ---
  const taskIdParam = useParams();
  // --- Selected in step 1 ---
  // Eventually we should save the full objects instead of strings
  // But right now the backend only returns the strings when saving
  const [action, setAction] = useState("reporting");
  const [taskType, setTaskType] = useState(preWarmTask);
  const [studyType, setStudyType] = useState("ann");
  const [client, setClient] = useState(null);
  const [title, setTitle] = useState("ANN MISight Prewarm Cache");
  const [pageTitle, setPageTitle] = useState("");
  // --- Selected and sent in step 2 ---
  const [taskConfig, setTaskConfig] = useState({});
  const [isRequired, setIsRequired] = useState([]);
  const [taskStatus, setTaskStatus] = useState("DRAFT");

  // --- Steps holder
  const [step, setStep] = useState(1);

  // Loading undone by useEffect data fetching
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const tabContents = useMemo(() => (
    <Card style={{ marginBottom: "1em" }}>
      <CardContent>
        <TaskStep1
          step={step}
          setStep={setStep}
          action={action}
          setAction={setAction}
          taskType={taskType}
          setTaskType={setTaskType}
          studyType={studyType}
          setStudyType={setStudyType}
          client={client}
          setClient={setClient}
          setTitle={setTitle}
          isRequired={isRequired}
          setIsRequired={setIsRequired}
          isPrewarmTask
          globalSearch={false}
        />
      </CardContent>
    </Card>
  ), [step, action, taskType, studyType, setTitle, client, isRequired]);

  // Grab Config on load
  useEffect(() => {
    let taskRequest;
    const taskIdValue = taskId || taskIdParam.taskIdParam;
    const configRequest = API.request({
      url: `/tasks/${taskIdValue}/config`,
      method: "GET",
    });

    if (taskIdValue) {
      taskRequest = API.request({
        url: `/tasks/${taskIdValue}`,
        method: "GET",
      });

      Promise.all([taskRequest, configRequest])
        .then(([taskData, configData]) => {
          const taskPayload = taskData.data.task;
          setClient(taskPayload.client);
          setTaskType(taskPayload.taskType);
          setTaskStatus(taskPayload.status);
          setStudyType(configData.data.payload.study);
          setTaskConfig(configData.data.payload || {});
          setLoading(false);
        })
        .catch((e) => {
          setError(e);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [taskIdParam.taskIdParam, taskId]);
  useEffect(() => {
    setPageTitle(taskIdParam.taskIdParam ? "Edit Task" : "Create Task");
  }, [taskIdParam.taskIdParam]);
  usePageTitle(pageTitle);
  if (loading) {
    return <Spinner size="2em" />;
  }

  if (error) {
    return <div>Error loading the task</div>;
  }

  const displayHeader = (props) => {
    const createDisplayTaskString = () => {
      const checkboxHeaderMapping = {
        ria_only: "RIA",
        reverse: "Remove Tags",
        is_qa_report: "Generate QA Reports",
      };
      /* eslint-disable no-nested-ternary */
      /* eslint-disable valid-typeof */
      return props.length
        ? props.reduce((headerValue, property) => {
          const taskValue = taskConfig[property];
          return `${headerValue}${typeof taskValue === "string"
            ? ` - ${taskValue.toUpperCase()}`
            : typeof taskValue === "boolean" && taskValue
              ? ` - ${checkboxHeaderMapping[property]}`
              : (typeof taskValue === "number" || typeof taskValue === "integer" || typeof taskValue === "object")
                ? ` - ${taskValue}`
                : ""
          }`;
        }, `${title}`)
          .replace(/_/g, " ")
          .trim()
        : undefined;
    };
    return (
      <div>
        {createDisplayTaskString()}
        {" "}
      </div>
    );
  };

  // EDIT VIEW

  if (taskStatus !== "DRAFT") {
    return <PageNotFound />;
  }

  if (taskIdParam.taskIdParam) {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Card>
            <CardContent>
              <TaskEdit
                action={action}
                taskType={taskType}
                studyType={studyType}
                client={client}
                setSubmitMessage={setSubmitMessage}
                title={title}
                taskId={taskIdParam.taskIdParam}
                taskConfig={taskConfig}
                setTaskConfig={setTaskConfig}
                displayHeader={displayHeader}
                setShowDialog={setShowDialog}
                setIsRequired={setIsRequired}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {step === 1
          ? (
            <Grid>
              {/* <Box style={{ width: "100%" }} /> */}
              <Box style={{ width: "100%", marginLeft: 10 }}>
                {tabContents}
              </Box>

            </Grid>
          )
          : (
            <Card>
              <CardContent>
                <TaskStep2
                  step={step}
                  setStep={setStep}
                  action={action}
                  taskType={taskType}
                  studyType={studyType}
                  client={client}
                  setClient={setClient}
                  setSubmitMessage={setSubmitMessage}
                  title={title}
                  displayHeader={displayHeader}
                  taskConfig={taskConfig}
                  setTaskConfig={setTaskConfig}
                  isRequired={isRequired}
                  setIsRequired={setIsRequired}
                  isPreWarmTask
                />
              </CardContent>
            </Card>
          )}
      </div>
    </div>
  );
}
