import React, { forwardRef, useState, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Formik, Form, Field, FieldArray } from "formik";
import { useDispatch } from "react-redux";
import useSWR from "swr";
import * as Yup from "yup";
import FormAlerts from "../../components/FormAlerts";
import LMSCheckbox from "../../components/input/Formik/base/LMSCheckBox";
import LMSTextField from "../../components/input/Formik/base/LMSTextField";
import { fetchingLMS, fetchingLMSDone, setOptions } from "../../state";
import { API } from "../../utils/api";
import { fetcher } from "../../utils/swr";

const Schema = Yup.object().shape({
  display_name: Yup.string().required("Name is required"),
  industry: Yup.string().required("Industry is required"),
  type: Yup.string().required("Type is required"),
  client_success_manager: Yup.string().required("Client Success Manager is required"),
  abbreviation: Yup.string().required("Abbreviation is required"),
  mnemonic: Yup.string().required("Mnemonic is required"),
  exclude_dnc_registered: Yup.boolean(),
});

const CLIENT_SUCCESS_USERGRP = "client-success";
const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
  },
  select: {
    borderRadius: 10,
  },
  removeDomainButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addDomainButton: {
    width: 120,
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.primary.light,
  },
  confirmButton: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
  createButton: {
    marginTop: "1%",
    marginBottom: "1%",
  },
}));

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function CreateCompany({ mutateCompanies }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [createProfileSessionNum, setCreateProfileSessionNum] = useState(0);

  const dispatch = useDispatch();

  const industries = ["Technology", "Service"];
  const types = ["Customer", "Partner"];

  const { data: managers } = useSWR(
    `/users?group_name=${CLIENT_SUCCESS_USERGRP}&pool=cognito_group`,
    fetcher,
  );

  const handleClose = useCallback(() => {
    setError(null);
    setSuccess(null);
    setDialogOpen(false);
    setCreateProfileSessionNum((prevState) => prevState + 1);
  }, [setDialogOpen]);

  const createCompany = useCallback(
    (companyData) => {
      const domains = companyData.clientDomains;
      const updatedCompanyData = {
        ...companyData,
        mnemonic: companyData.mnemonic.toUpperCase(),
        abbreviation: companyData.abbreviation.toUpperCase(),
        is_active: true,
      };
      delete updatedCompanyData.clientDomains;

      setLoading(true);
      setError(null);
      setSuccess(null);
      const url = "/lms/api/v1/discovery/client";

      API.request({ url, method: "POST", data: updatedCompanyData })
        .then((clientId) => {
          // Whitelist all the domains
          const domainsRequests = [];
          domains.forEach((domain) => {
            if (domain !== "") {
              domainsRequests.push(
                API.request({
                  url: "/lms/api/v1/client/clientdomainwhitelist",
                  method: "POST",
                  data: {
                    client_id: clientId.data,
                    domain,
                  },
                }),
              );
            }
          });
          Promise.all(domainsRequests).then(() => {
            setSuccess("-- Company Profile Successfully Created! --");
            setLoading(false);
            mutateCompanies(); // Trigger the re-fetch
            dispatch(fetchingLMS({ element: "clients" }));
            API.request({ url: "/lms/api/v1/client/client", method: "GET" }).then((response) => {
              dispatch(setOptions({ option: "lms_backend_clients", value: response.data }));
              dispatch(fetchingLMSDone({ element: "clients" }));
            }).catch((e) => { console.log(e); });
          }).catch(() => {
            setSuccess("-- Company Profile Successfully Created! --");
            setError("Encountered some error while adding domains");
            setLoading(false);
            mutateCompanies(); // Trigger the re-fetch
            dispatch(fetchingLMS({ element: "clients" }));
            API.request({ url: "/lms/api/v1/client/client", method: "GET" }).then((response) => {
              dispatch(setOptions({ option: "lms_backend_clients", value: response.data }));
              dispatch(fetchingLMSDone({ element: "clients" }));
            }).catch((e) => { console.log(e); });
          });
        })
        .catch((e) => {
          setError(e.response.data.detail);
          setLoading(false);
        });
    },
    [mutateCompanies, dispatch],
  );

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setDialogOpen(true)}
        className={classes.createButton}
      >
        Create Company
      </Button>
      <Formik
        key={createProfileSessionNum}
        initialValues={{
          display_name: "",
          industry: "",
          type: "",
          client_success_manager: "",
          abbreviation: "",
          mnemonic: "",
          clientDomains: [],
          exclude_dnc_registered: false,
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          createCompany(values);
        }}
      >
        {({ errors, touched, values }) => (
          <Dialog
            open={dialogOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth
          >
            <DialogTitle>Create Company</DialogTitle>
            <DialogContent>
              <Form>
                <LMSTextField
                  label="Display Name"
                  name="display_name"
                />
                <LMSTextField
                  label="Abbreviation"
                  name="abbreviation"
                />
                <LMSTextField
                  label="Mnemonic"
                  name="mnemonic"
                />
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                >
                  <InputLabel htmlFor="industry">Industry</InputLabel>
                  <Field
                    as={Select}
                    name="industry"
                    label="Industry"
                    className={classes.select}
                  >
                    {industries.map((industry) => (
                      <MenuItem key={industry} value={industry}>
                        {industry}
                      </MenuItem>
                    ))}
                  </Field>
                  <FormHelperText error>
                    {touched.industry && errors.industry ? errors.industry : ""}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                >
                  <InputLabel htmlFor="type">Type</InputLabel>
                  <Field
                    as={Select}
                    name="type"
                    label="Type"
                    className={classes.select}
                  >
                    {types.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Field>
                  <FormHelperText error>
                    {touched.type && errors.type ? errors.type : ""}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                >
                  <InputLabel htmlFor="client_success_manager">
                    Client Success Manager
                  </InputLabel>
                  <Field
                    as={Select}
                    name="client_success_manager"
                    label="Client Success Manager"
                    className={classes.select}
                  >
                    {managers ? (
                      managers.users.map((manager) => (
                        <MenuItem key={manager.name} value={manager.name}>
                          {manager.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        Loading...
                      </MenuItem>
                    )}
                  </Field>
                  <FormHelperText error>
                    {touched.client_success_manager
                      && errors.client_success_manager
                      ? errors.client_success_manager
                      : ""}
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <FieldArray
                    name="clientDomains"
                    render={(arrayHelpers) => (
                      <div>
                        {
                          values.clientDomains.map((_, index) => (
                            // eslint-disable-next-line
                            <div key={index}>
                              <Field
                                as={TextField}
                                name={`clientDomains.${index}`}
                                label={`Domain ${index + 1}`}
                                error={touched[`clientDomains.${index}`] && errors[`clientDomains.${index}`]}
                                validate={(domain) => {
                                  if (domain === "") {
                                    return "Domain empty";
                                  }
                                  if (domain?.match("@")) {
                                    return "Domain can not contain the @ symbol";
                                  }
                                  return undefined;
                                }}
                              />
                              <Button
                                className={classes.removeDomainButton}
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                Remove
                              </Button>
                              <FormHelperText error>
                                {touched.clientDomains?.[index]
                              && errors.clientDomains?.[index]
                                  ? errors.clientDomains?.[index]
                                  : ""}
                              </FormHelperText>
                            </div>
                          ))
                        }
                        <Button
                          className={classes.addDomainButton}
                          onClick={() => arrayHelpers.push("")}
                        >
                          Add Domain
                        </Button>
                      </div>
                    )}
                  />
                </FormControl>

                <LMSCheckbox
                  name="exclude_dnc_registered"
                  label="Exclude DNC Registered"
                />
                <FormAlerts
                  saving={loading}
                  success={success}
                  error={error}
                  setSuccess={setSuccess}
                  setError={setError}
                />
              </Form>
            </DialogContent>
            {success ? (
              <div>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    className={classes.cancelButton}
                    disabled={loading}
                  >
                    Close
                  </Button>
                </DialogActions>
              </div>
            ) : (
              <DialogActions>
                <Button onClick={handleClose} className={classes.cancelButton}>
                  Cancel
                </Button>
                <Button
                  onClick={() => createCompany(values)}
                  className={classes.confirmButton}
                  disabled={
                    Object.keys(errors).length > 0
                    || !Object.keys(touched).length
                  }
                >
                  Create
                </Button>
              </DialogActions>
            )}
          </Dialog>
        )}
      </Formik>
    </>
  );
}
