import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SaveAlt, Search } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import InfoIcon from "@mui/icons-material/Info";
import { Card, CardContent, FormControl, Accordion, AccordionSummary, Typography, Chip, Snackbar, LinearProgress, IconButton, Grid, Tooltip, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, useTheme } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { AgGridReact } from "ag-grid-react";
import saveAs from "file-saver";
import { useSelector } from "react-redux";
import TableLoadingOverlay from "../../../components/MaterialTableLoadingOverlay";
import MaterialTable from "../../../components/Table";
import OptionSelect from "../../../components/input/OptionSelect";
import usePageTitle from "../../../hooks/usePageTitle";
import { textPerPalette } from "../../../theme/common";
import { API } from "../../../utils/api";
import { studyTypes } from "../create_wizard/TaskMapping";
import ClientButton from "./ClientButton";
import ColorStatus from "./ColorStatus";
import ProductOverview from "./ProductOverview";
import Shape from "./Shape";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../../theme/agGridThemes/statusTableStyle.css";
import "../../../theme/agGridThemes/fileLoadMonitorStyle.css";

const taskStatusSortOrder = {
  FAILED: 1,
  ABORTED: 2,
  NOT_ATTEMPTED: 3,
  QUEUED: 4,
  RUNNING: 5,
  RUNNING_QUEUE: 6,
  SUCCEEDED: 7,
  NOT_EXPECTED: 8,
};

const annTaskList = [
  { id: "client_name", label: "Client" },
  { id: "preprocessing_ann", label: "Pre-Processing VA", file_type: "VA" },
  { id: "preprocessing_ann", label: "Pre-Processing FIA", file_type: "FIA" },
  { id: "preprocessing_load_sales_ann", label: "VA Sales Loader", file_type: "VA" },
  { id: "preprocessing_load_sales_ann", label: "FIA Sales Loader", file_type: "FIA" },
  { id: "preprocessing_load_office_map_discovery", label: "Office Maps" },
  { id: "preprocessing_load_territory_map_discovery", label: "Territory Map" },
  { id: "preprocessing_channel_loader_discovery", label: "Channel Loader" },
  { id: "preprocessing_load_zone_map", label: "Zone Map" },
  { id: "reporting_ann_dd_identifier_mapping", label: "DD Identifier Map" },
  { id: "reporting_ann_vetting_report", label: "LMS Vetting Report" },
  { id: "preprocessing_ann_populate_sales_totals_discovery", label: "Populate Sales Totals" },
  { id: "reporting_ann_standard_feed_v2_discovery", label: "Standard Feed" },
  { id: "misight_prewarm_cache", label: "MISight Prewarm Cache" },
];

const sortColumn = (a, b) => (taskStatusSortOrder[a.status] - taskStatusSortOrder[b.status]);

const tableCellRender = (params, theme) => {
  if (params.data?.name === "Total") {
    return (
      <div style={{ textAlign: "center" }}>
        <Chip
          label={`${params.data[params.colDef.field]?.successful_clients} / ${params.data[params.colDef.field]?.expected_clients}`}
          style={{
            borderRadius: "10px",
            height: "40px",
            fontSize: "15px",
            marginTop: "10px",
            color: textPerPalette(theme),
            backgroundColor: theme.palette.primary.main,
          }}
        />
      </div>
    );
  }
  return (<Shape taskDetails={params.data[params.colDef.field]} />);
};

const filterClientColumnStyle = {
  muiTableHeadCellProps: {
    align: "center",
  },
  muiTableBodyCellProps: {
    align: "center",
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarStatus = (status, message, state, setState, dowloadingType) => (
  <Snackbar
    open={state?.status?.length > 0}
    autoHideDuration={dowloadingType ? undefined : 4000}
    onClose={() => { setState({ status: "", message: "" }); }}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
  >
    <div>
      <Alert severity={status}>
        {dowloadingType && <LinearProgress />}
        {message}
      </Alert>
    </div>
  </Snackbar>
);

export default function FileLoadMonitor() {
  usePageTitle("File Load Monitor");
  const [loading, setLoading] = useState(true);
  const [studyType, setStudyType] = useState("ann");
  const [allClientsData, setAllClientsData] = useState([]);
  const [clientTaskMapping, setClientTaskMapping] = useState([]);
  const [productionCycle, setProductionCycle] = useState("current");
  const [productOverviewExpanded, setProductOverviewExpanded] = useState(true);
  const [refreshTable, setRefreshTable] = useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [filterDialogEntries, setFilterDialogEntries] = useState({ previous: [], current: [] });
  const [selectAllState, setSelectAllState] = useState({ checked: true, indeterminate: false });
  const memoizedLoadingOverlay = useMemo(() => <TableLoadingOverlay />, []);
  const headers = [];
  const storedProductionCycle = useSelector((state) => state.formOptions.productionCycle);
  const defaultColDef = useMemo(() => ({
    wrapHeaderText: true,
    autoHeaderHeight: true,
    resizable: true,
    sortable: true,
    autoHeight: true,
  }), []);
  const gridRef = useRef();
  const textboxRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const containerStyle = useMemo(() => ({ height: "100%", width: "1850px" }), []);
  const theme = useTheme();

  const columnLabels = [
    { headerName: "Pre-Processing VA", field: "preprocessing_va", sorting: true },
    { headerName: "Pre-Processing FIA", field: "preprocessing_fia", sorting: true },
    { headerName: "VA Sales Loader", field: "va_sales", sorting: true },
    { headerName: "FIA Sales Loader", field: "fia_sales", sorting: true },
    { headerName: "Office Maps", field: "preprocessing_load_office_map_discovery", sorting: true },
    { headerName: "Territory Map", field: "preprocessing_load_territory_map_discovery", sorting: true },
    { headerName: "Channel Loader", field: "preprocessing_channel_loader_discovery", sorting: true },
    { headerName: "Zone Map", field: "preprocessing_load_zone_map", sorting: true },
    { headerName: "DD Identifier Map", field: "reporting_ann_dd_identifier_mapping", sorting: true },
    { headerName: "LMS Vetting Report", field: "reporting_ann_vetting_report", sorting: true },
    { headerName: "Populate Sales Totals", field: "preprocessing_ann_populate_sales_totals_discovery", sorting: true },
    { headerName: "Standard Feed", field: "reporting_ann_standard_feed_v2_discovery", sorting: true },
    { headerName: "MISight Prewarm Cache", field: "misight_prewarm_cache", sorting: true },
  ];

  const sortRowsByClientName = (rows) => rows.sort((a, b) => (a.name < b.name ? -1 : 1));

  const [downloadExcelSnackbarStatus, setDownloadExcelSnackbarStatus] = useState({ status: "", message: "" });
  const [filterSnackBarStatus, setFilterSnackBarStatus] = useState({ status: "", message: "" });

  const downloadExcel = () => {
    setDownloadExcelSnackbarStatus({ status: "info", message: "Downloading Excel File..." });
    API.request({
      url: "/lms/api/v1/file_load_monitor/get_fileloadmonitor_excel",
      method: "GET",
      params: { study_type: studyType, cycle: productionCycle },
      axiosConfig: {
        responseType: "blob",
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      },
    }).then((response) => {
      saveAs(new Blob([response.data]), `ANNFileLoadMonitor_${productionCycle === "current" ? storedProductionCycle[1] : storedProductionCycle[2]}.xlsx`);
      setDownloadExcelSnackbarStatus({ status: "success", message: "File Downloaded Successfully !" });
    }).catch(error => {
      console.error(error);
      setDownloadExcelSnackbarStatus({ status: "error", message: "Error Downloading Excel File. Please Try again !" });
    });
  };

  useEffect(() => {
    // Product Overview
    const totalsChips = { name: "Total" };
    const result = [];
    setAllClientsData([]);
    setLoading(true);
    API.request({
      url: "/lms/api/v1/file_load_monitor/get_product_overview",
      method: "GET",
      params: { study_type: studyType, cycle: productionCycle },
    }).then(response => {
      setAllClientsData(response.data.analytics);
      Object.values(response.data.files).forEach((task) => {
        totalsChips[task?.title] = {
          successful_clients: task?.successful_clients,
          expected_clients: task?.expected_clients,
        };
      });
      setClientTaskMapping([totalsChips, ...sortRowsByClientName(result)]);
      setLoading(!(result.length > 0));
    });
    // File Monitor Table
    API.request({
      url: "/lms/api/v1/file_load_monitor/get_clients_data",
      method: "GET",
      params: { cycle: productionCycle },
    }).then(response => {
      const filterRowData = [];
      Object.entries(response.data).forEach(([key, value]) => {
        const components = {};
        const isFiltered = Object.values(value).reduce((accumulator, currentValue) => accumulator && currentValue?.is_filtered, true);
        filterRowData.push({
          name: key,
          includeClient: !isFiltered,
        });
        Object.entries(value).forEach(([taskType, taskDetail]) => {
          components[taskType] = {
            id: taskDetail.task_id,
            ranBy: taskDetail.username,
            date: taskDetail.updated_at,
            status: taskDetail.task_status,
            workflowId: taskDetail.workflow_id,
          };
        });
        if (!isFiltered) {
          result.push({ name: key, ...components });
        }
      });
      setClientTaskMapping([totalsChips, ...sortRowsByClientName(result)]);
      setFilterDialogEntries({ previous: sortRowsByClientName(filterRowData), current: sortRowsByClientName(filterRowData) });
      setLoading(!(Object.keys(totalsChips).length > 2));
    }).catch(error => {
      console.error(error);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyType, productionCycle, refreshTable]);

  if (clientTaskMapping.length > 0) {
    headers.push(
      {
        headerName: "Client",
        field: "name",
        pinned: "left",
        width: 250,
        cellStyle: { border: "none" },
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (params) => (<ClientButton rowData={params.data} refreshTable={setRefreshTable} taskList={columnLabels} states={{ production_cycle: productionCycle, study_type: studyType, refresh_table: refreshTable }} />),
        comparator: (a, b) => (a !== "Total" && b !== "Total" ? a.localeCompare(b) : 0),
      },
      ...columnLabels.map((column) => ({
        ...column,
        cellRenderer: (params) => tableCellRender(params, theme),
        comparator: (a, b) => sortColumn(a, b),
        width: 115,
      })),
    );
  }
  const ordered = annTaskList.map(item => headers.find(x => x.headerName === item.label));
  const columns = headers.filter(item => ordered.includes(item));

  const productionCycleOptions = [
    { id: "current", label: "Current Cycle" },
    { id: "previous", label: "Previous Cycle" },
  ];

  const views = [{
    id: "Consortium View", label: "Consortium View",
  }];
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      textboxRef.current.value,
    );
  }, []);
  const tableWrapperRef = useRef(null);
  const pinFileLoadMonitorHeader = (entries) => {
    const [entry] = entries;
    const isTableVisible = entry.isIntersecting;
    if (isTableVisible) {
      document.querySelector(".ag-header")?.classList.add("fixed-header-style");
    } else {
      document.querySelector(".ag-header")?.classList.remove("fixed-header-style");
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(pinFileLoadMonitorHeader, {
      rootMargin: "0% 0% -90% 0%",
    });
    if (!loading) {
      if (tableWrapperRef.current) {
        observer.observe(tableWrapperRef.current);
      }
    }
  }, [loading]);

  useEffect(() => {
    const includeCount = filterDialogEntries.current.filter((item) => item.includeClient).length;
    const totalCount = filterDialogEntries.current.length;
    if (includeCount > 0 && includeCount < totalCount) {
      setSelectAllState({ checked: true, indeterminate: true });
    } else if (includeCount > 0 && includeCount === totalCount) {
      setSelectAllState({ checked: true, indeterminate: false });
    } else {
      setSelectAllState({ checked: false, indeterminate: false });
    }
  }, [filterDialogEntries]);

  const handleFilterCheckboxChange = (rowData) => {
    const dataUpdate = [...filterDialogEntries.current].map((row) => ((row.name === rowData.name) ? { ...row, includeClient: !row.includeClient } : row));
    setFilterDialogEntries({ ...filterDialogEntries, current: [...dataUpdate] });
  };

  const filterDialogColumns = [{
    header: "Clients",
    accessorKey: "name",
    render: (rowData) => (
      <Chip
        variant="outlined"
        style={{
          width: 200,
          textAlign: "center",
          fontSize: "15px",
          backgroundColor: "white",
          color: "black",
        }}
        label={rowData.name}
      />
    ),
    ...filterClientColumnStyle,
  }, {
    header: "Include?",
    accessorKey: "includeClient",
    render: (rowData) => (
      <Checkbox
        onChange={() => handleFilterCheckboxChange(rowData)}
        checked={rowData?.includeClient || false}
        name={rowData?.name}
      />
    ),
    ...filterClientColumnStyle,
  }];

  const handleDialogClose = () => {
    setFilterDialogEntries({ ...filterDialogEntries, current: filterDialogEntries.previous });
    setOpenFilterDialog(false);
  };

  const handleDialogSave = () => {
    const filterClients = filterDialogEntries.current.map((item) => ({ client: item.name, include_client: item.includeClient }));
    setFilterSnackBarStatus({ status: "info", message: "Settting Filters..." });
    API.request({
      url: "/lms/api/v1/file_load_monitor/set_clients_filter",
      method: "POST",
      data: { filter_clients: filterClients, production_cycle: productionCycle },
    }).then(() => {
      setOpenFilterDialog(false);
      setFilterSnackBarStatus({ status: "success", message: "Filters Set Successfully !" });
      setRefreshTable(!refreshTable);
    }).catch((error) => {
      console.error(error);
      setFilterSnackBarStatus({ status: "error", message: "Error in Setting Filters. Please Try Again !" });
    });
  };

  const handleSelectAllChange = () => {
    if (selectAllState.checked || selectAllState.indeterminate) {
      const allUnselected = filterDialogEntries.current.map((item) => ({ ...item, includeClient: false }));
      setFilterDialogEntries({ ...filterDialogEntries, current: allUnselected });
      setSelectAllState({ indeterminate: false, checked: false });
    } else {
      const allSelected = filterDialogEntries.current.map((item) => ({ ...item, includeClient: true }));
      setFilterDialogEntries({ ...filterDialogEntries, current: allSelected });
      setSelectAllState({ indeterminate: false, checked: true });
    }
  };

  return (
    <div>
      <Dialog open={openFilterDialog} onClose={() => setOpenFilterDialog(false)}>
        <DialogTitle>
          Filter Clients for File Load Monitor
          <Tooltip title="This filter changes the view of the file load monitor for all users. Please use this feature carefully.">
            <IconButton aria-label="info" size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "inline-flex" }}>
            <Checkbox
              checked={selectAllState.checked}
              indeterminate={selectAllState.indeterminate}
              onClick={handleSelectAllChange}
              style={{ paddingBottom: 10 }}
            />
            <Typography style={{ paddingTop: 11 }}>
              SELECT/DE-SELECT ALL
            </Typography>
          </div>
          <MaterialTable
            columns={filterDialogColumns}
            data={filterDialogEntries.current}
            enablePagination={false}
            enableBottomToolbar={false}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={filterSnackBarStatus.status === "info"} color="primary" variant="text" onClick={handleDialogClose}>
            Close
          </Button>
          <Button disabled={filterSnackBarStatus.status === "info"} color="secondary" variant="text" onClick={handleDialogSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Card style={{ marginTop: 20, justifyContent: "space-evenly" }}>
        <CardContent style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <FormControl style={{ minWidth: 360, marginRight: 10 }}>
            <OptionSelect
              value="Consortium View"
              options={views}
              label="Select View"
            />
          </FormControl>
          <FormControl style={{ minWidth: 360, marginRight: 10 }}>
            <OptionSelect
              value="ann"
              options={studyTypes.filter(o => o.fileLoadMonitor)}
              label="Study"
              onChange={(selectedOption) => setStudyType(selectedOption)}
            />
          </FormControl>
          <FormControl style={{ minWidth: 360, marginRight: 10 }}>
            <OptionSelect
              value={productionCycle}
              options={productionCycleOptions}
              label="Production Cycle"
              onChange={setProductionCycle}
            />
          </FormControl>
        </CardContent>
      </Card>

      <Accordion
        expanded={productOverviewExpanded}
        onChange={() => setProductOverviewExpanded(!productOverviewExpanded)}
        square={false}
        style={{ borderRadius: "10px", marginBottom: 20, marginTop: 20 }}
      >
        {studyType === "ann" && (
          <Card style={{ display: "flex", justifyContent: "space-evenly", backgroundColor: theme.palette.primary.main, height: "auto", justifyItems: "center" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: textPerPalette(theme) }} />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <Typography variant="h5" component="h2" style={{ color: textPerPalette(theme) }}>
                All Clients Product Overview
              </Typography>
            </AccordionSummary>
          </Card>
        )}
        {studyType === "ann" && <ProductOverview allClientsData={allClientsData} />}
      </Accordion>

      {studyType === "ann" && clientTaskMapping
        && (
          <Card style={{ marginTop: 6 }}>
            <CardContent style={{ display: "flex", paddingTop: 20, justifyContent: "center", height: !loading ? "max-content" : "200px" }}>
              {loading ? memoizedLoadingOverlay
                : (
                  <div style={containerStyle}>
                    <Grid container style={{ justifyContent: "space-between" }}>
                      <Grid item xs={8}>
                        <Typography align="left" variant="h6" component="div">
                          ANN File Load Monitor
                        </Typography>
                      </Grid>
                      <Grid container display="flex" justifyContent="flex-end">
                        <Button
                          color="primary"
                          startIcon={<FilterListIcon />}
                          onClick={() => { setOpenFilterDialog(true); }}
                          style={{ backgroundColor: theme.palette.primary.main, color: textPerPalette(theme), maxHeight: 45, marginTop: 5, marginRight: 5 }}
                        >
                          Filter Clients
                        </Button>
                        <Search style={{ marginTop: 20, marginRight: 5 }} />
                        <TextField
                          inputRef={textboxRef}
                          label="Search..."
                          onInput={onFilterTextBoxChanged}
                          style={{ marginBottom: 10 }}
                          variant="standard"
                        />
                        <Tooltip title="Download Excel" placement="top">
                          <IconButton
                            color="primary"
                            aria-label="download_button"
                            onClick={downloadExcel}
                            style={{ paddingTop: 15 }}
                            size="large"
                          >
                            <SaveAlt />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <div className="ag-theme-alpine ag-theme-status-table" style={gridStyle} ref={tableWrapperRef}>
                      <AgGridReact
                        className={`ag-theme-file-loadmonitor-${theme.palette.themeName}`}
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnDefs={columns}
                        rowData={clientTaskMapping}
                        rowBuffer={100}
                        overlayNoRowsTemplate="Loading Data"
                        suppressRowHoverHighlight
                        domLayout="autoHeight"
                      />
                    </div>
                  </div>
                )}
            </CardContent>
            <CardContent style={{ display: "flex" }}>
              {!loading
                && (
                  <ColorStatus />
                )}
            </CardContent>
          </Card>
        )}
      {downloadExcelSnackbarStatus.status.length > 0 && SnackbarStatus(downloadExcelSnackbarStatus.status, downloadExcelSnackbarStatus.message, downloadExcelSnackbarStatus, setDownloadExcelSnackbarStatus, downloadExcelSnackbarStatus.status === "info")}
      {filterSnackBarStatus.status.length > 0 && SnackbarStatus(filterSnackBarStatus.status, filterSnackBarStatus.message, filterSnackBarStatus, setFilterSnackBarStatus, filterSnackBarStatus.status === "info")}
    </div>
  );
}
