import React, { useEffect, useState } from "react";
import { Link, MenuItem } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useSelector } from "react-redux";
import { API } from "../../../utils/api";
import { taskTypes } from "../create_wizard/TaskMapping";

export default function TaskClone({ taskId, taskData }) {
  const cognitoGroups = useSelector(state => state.application.cognitoGroups);
  const [newTaskId, setNewTaskID] = useState(null);
  const [taskPayload, setTaskPayload] = useState({});
  const [loading, setLoading] = useState(true);
  const [checkTask, setCheckTask] = useState();
  useEffect(() => {
    const url = `/tasks/${taskId}/config`;
    API.request({ url, method: "GET" }).then(
      (response) => {
        setCheckTask(taskTypes.filter(taskType => taskType.id === taskData.taskType)[0]);
        setTaskPayload(response.data.payload);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      },
    );
  }, [taskId, setTaskPayload, taskData]);

  async function clone(task, payload) {
    setLoading(true);
    const data = {
      taskType: task.taskType,
      client: task.client,
      studyType: task.studyType,
      taskPayload: payload,
      title: task.title,
      cloned_from: task.taskId,
    };
    delete data.taskPayload.attachment;
    const clonedTaskId = await API.request({
      url: "/tasks",
      method: "POST",
      data,
    }).then(
      (r) => {
        if (!r.data.taskId) console.log("Task could not be created");
        setLoading(false);
        return r.data.taskId;
      },
      (e) => {
        console.log("Task could not be created");
        console.error(e);
        setLoading(false);
        return null;
      },
    );
    setNewTaskID(clonedTaskId);
  }

  if (loading) {
    return <Skeleton style={{ margin: "0.5em" }} />;
  }
  if (newTaskId) {
    return (
      <MenuItem key={newTaskId}>
        <Link href={`/edit/${newTaskId}`} color="inherit">
          {`New Draft ID: ${newTaskId}`}
        </Link>
      </MenuItem>
    );
  }
  return (

    (
      <MenuItem key="clone" disabled={checkTask?.dev_only_task && !cognitoGroups.includes("super-admin")} onClick={() => clone(taskData, taskPayload)}>
        Clone
      </MenuItem>
    )
  );
}
