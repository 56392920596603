export const reportMappings = {
  reporting_ann_lms_qa_dashboard: [{
    id: "ann_quality_control_audit",
    label: "Quality Control Audit",
    items: [
      {
        id: "audit-checklist",
        label: "QA Audit Checklist",
        dataURL: "/qa/audit-checklist",
        items: [
          {
            id: "qa_audit_checklist_item",
            label: "QA Audit Checklist",
            dataKey: "qa_audit_checklist",
            componentType: "table",
            gridSize: 12,
          },
        ],
      },
      {
        id: "data-conversion",
        label: "Data Conversion",
        dataURL: "qa/data-conversion",
        items: [
          {
            id: "firm_conversion_by_client",
            label: "Firm Conversion By Client",
            componentType: "table",
            dataKey: "firm_conversion_by_client",
            gridSize: 12,
            formattingOptions: { columnFormatting: true, columnEditing: false, bottomRowPinned: true },
          },
          {
            id: "office_conversion_by_client",
            label: "Office Conversion By Client",
            componentType: "table",
            dataKey: "office_conversion_by_client",
            gridSize: 12,
            formattingOptions: { columnFormatting: true, columnEditing: false, bottomRowPinned: true },
          },
          {
            id: "quarter_over_quarter_raw_conversion_by_client",
            label: "Quarter over Quarter Raw conversion By client",
            componentType: "table",
            dataKey: "quarter_over_quarter_raw_conversion_by_client",
            gridSize: 12,
            formattingOptions: { columnFormatting: true, columnEditing: false, bottomRowPinned: true },
          },
        ],
      },
      {
        id: "reportable-data",
        label: "Reportable Data",
        dataURL: "qa/reportable-data",
        items: [
          {
            id: "reportable_data_by_product_type",
            label: "Reportable Data by Product Type",
            componentType: "table",
            dataKey: "reportable_data_by_product_type",
            gridSize: 12,
            formattingOptions: { columnFormatting: true, columnEditing: false, bottomRowPinned: true },
          },
          {
            id: "offices_with_high_client_concentration_live",
            label: "Offices with High Client Concentration (Live)",
            componentType: "officesHighConcentration",
            dataKey: "offices_with_high_client_concentration_live",
            gridSize: 12,
            formattingOptions: { columnFormatting: true, columnEditing: false, bottomRowPinned: true, pagination: true },
          },
          {
            id: "reportable_data_by_firm_sale_volume",
            label: "Reportable Data by Firm (Sale Volume)",
            componentType: "table",
            dataKey: "reportable_data_by_firm_sale_volume",
            gridSize: 12,
            formattingOptions: { columnFormatting: true, columnEditing: false, bottomRowPinned: true },
          },
          {
            id: "reportable_data_by_firm_branch",
            label: "Reportable Data by Firm (Branch Count)",
            dataKey: "reportable_data_by_firm_branch",
            componentType: "table",
            gridSize: 12,
            formattingOptions: { columnFormatting: true, columnEditing: false },
          },
        ],
      },

      {
        id: "negative-volume-review",
        label: "Negative Volumes Review",
        dataURL: "qa/negative-volume-review",
        items: [
          {
            id: "negative_firm_review",
            label: "Negative Firm Review",
            componentType: "table",
            dataKey: "negative_firm_review",
            gridSize: 12,
            formattingOptions: { columnFormatting: true, columnEditing: false, bottomRowPinned: true },
          },
          {
            id: "negative_office_review",
            label: "Negative Office Review",
            componentType: "table",
            dataKey: "negative_office_review",
            gridSize: 12,
            formattingOptions: { columnFormatting: true, columnEditing: false, bottomRowPinned: true },
          },
        ],
      },
      {
        id: "physical-branch-id-accuracy",
        label: "DD Physical Branch Id Accuracy",
        dataURL: "qa/physical-branch-id-accuracy",
        items: [
          {
            id: "dd_state_accuracy",
            label: "DD State Accuracy",
            componentType: "table",
            dataKey: "dd_state_accuracy",
            gridSize: 12,
            formattingOptions: { columnFormatting: true, columnEditing: false },
          },
        ],
      },
    ],
  },
  {
    id: "sales_file_vetting",
    label: "Sales File Vetting",
    items: [
      {
        id: "va",
        label: "VA",
        apiParams: { fileType: "va" },
        dataURL: "lms/api/v1/qa/sales_file_vetting",
        items: [
          {
            id: "va_qoq_check",
            label: "VA QoQ Check",
            componentType: "salesVettingTable",
            gridSize: 12,
            dataKey: "qoq_sales_variances",
            formattingOptions: { columnFormatting: true, columnEditing: true },
          },
          {
            id: "va_lvl_check",
            label: "VA LvL Check",
            componentType: "salesVettingTable",
            gridSize: 12,
            dataKey: "lvl_sales_variances",
            formattingOptions: { columnFormatting: true, columnEditing: true },
          },
        ],
      },
      {
        id: "fia",
        label: "FIA",
        apiParams: { fileType: "fia" },
        dataURL: "lms/api/v1/qa/sales_file_vetting",
        items: [
          {
            id: "fia_qoq_check",
            label: "FIA QoQ Check",
            componentType: "salesVettingTable",
            gridSize: 12,
            dataKey: "qoq_sales_variances",
            formattingOptions: { columnFormatting: true, columnEditing: true },
          },
          {
            id: "fia_lvl_check",
            label: "FIA LvL Check",
            componentType: "salesVettingTable",
            gridSize: 12,
            dataKey: "lvl_sales_variances",
            formattingOptions: { columnFormatting: true, columnEditing: true },
          },
        ],
      },
    ],
  },
  {
    id: "file_load_monitor_drill_down",
    label: "File Load Monitor Drilldown",
    items: [
      {
        id: "m2t-status",
        label: "M2T Status",
        dataURL: "/qa/m2t-status",
        items: [
          { id: "ann_sales_data_and_sales_aggregate", dataKey: "ann_sales_data_and_sales_aggregate", componentType: "table", gridSize: 12, formattingOptions: { columnFormatting: true, columnEditing: false, bottomRowPinned: true }, label: "ANN Sales Data and Sales Aggregate" },
        ],
      },
      {
        id: "historical-load-trend",
        label: "Historical Load Trend",
        dataURL: "/qa/historical-load-trend",
        items: [
          { id: "current_end_of_day", componentType: "table", dataKey: "current_end_of_day", gridSize: 12, formattingOptions: { columnFormatting: false, columnEditing: false }, label: "Current End of Day" },
          {
            id: "current_end_of_day_bar_chart",
            label: "Clients Loaded QTD (current EOD)",
            componentType: "barChart",
            gridSize: 10,
            dataKey: "current_end_of_day_bar_chart",
          },
          { id: "prior_end_of_day", componentType: "table", dataKey: "prior_end_of_day", gridSize: 12, formattingOptions: { columnFormatting: false, columnEditing: false }, label: "Prior End of Day" },
          {
            id: "prior_end_of_day_bar_chart",
            label: "Clients Loaded QTD (prior EOD)",
            componentType: "barChart",
            gridSize: 10,
            dataKey: "prior_end_of_day_bar_chart",
          },
        ],
      },
      {
        id: "sales-load-summary",
        label: "Sales Load Summary",
        dataURL: "/qa/sales-load-summary",
        items: [{ id: "sales_load_summary", componentType: "table", dataKey: "sales_load_summary", gridSize: 12, formattingOptions: { columnFormatting: false, columnEditing: false, bottomRowPinned: true }, label: "Sales Load Summary" }],
      },
      {
        id: "last-load-date",
        label: "Last Load Date",
        dataURL: "/qa/last-load-date",
        items: [
          { id: "va_sales_data", componentType: "table", dataKey: "va_sales_data", gridSize: 12, formattingOptions: { columnFormatting: false, columnEditing: false }, label: "ANN VA SALES DATA" },
          { id: "fia_sales_data", componentType: "table", dataKey: "fia_sales_data", gridSize: 12, formattingOptions: { columnFormatting: false, columnEditing: false }, label: "ANN FIA SALES DATA" },
          { id: "va_sales_data_legacy", componentType: "table", dataKey: "va_sales_data_legacy", gridSize: 12, formattingOptions: { columnFormatting: false, columnEditing: false }, label: "VA SALES DATA (Legacy)" },
        ],
      },
      {
        id: "file-load-detail",
        label: "File Load Detail",
        dataURL: "/qa/file-load-detail",
        items: [{ id: "file_load_detail", componentType: "table", dataKey: "file_load_detail", gridSize: 12, formattingOptions: { columnFormatting: false, columnEditing: false }, label: "File Load Detail" }],
      },
    ],
  },
  {
    id: "download_lms_qa_reports",
    label: "Download LMS QA Reports",
    items: [
      {
        id: "qa_dashboard_quarters",
        label: "Download LMS QA Reports",
        items: [
          {
            id: "qa_dashboard_quarters",
            label: "Download LMS QA Reports",
            componentType: "folders",
            gridSize: 12,
          },
        ],
      },
    ],
  }],
  reporting_ann_misight_qa_dashboard: [{
    id: "ann_sanity_check",
    label: "ANN Sanity Check",
    items: [
      {
        id: "sanity-check",
        label: "Sanity Check Comparison",
        dataURL: "/qa/sanity-check",
        items: [
          {
            id: "ann_total_users",
            label: "Total Number of Users",
            componentType: "dataGrid",
            gridSize: 12,
            dataKey: "user_count_by_resource",
          },
          {
            id: "ann_sanity_check_summary",
            label: "Users With Pass Test",
            componentType: "pieGraph",
            gridSize: 6,
            dataKey: "users_passed",
          },
          {
            id: "ann_users_with_failed_test",
            label: "Users with Failed Test",
            componentType: "barChart",
            gridSize: 6,
            dataKey: "users_failed",
          },
          {
            id: "ann_users_with_failed_test_based_on_organization",
            label: "Users with Failed Test based on Organization (Graph)",
            componentType: "columnChart",
            gridSize: 12,
            dataKey: "users_with_failure_by_organization",
            yAxisTitle: "Users",
          },
          {
            id: "ann_users_with_failed_test_based_on_organization_table",
            label: "Users with Failed Test based on Organization",
            componentType: "table",
            gridSize: 12,
            dataKey: "users_with_failure",
          },
          {
            id: "active_users",
            label: "Active Users",
            componentType: "tableWithToggle",
            gridSize: 12,
            formattingOptions: {
              columnFormatting: false,
              columnEditing: false,
            },
            dataKey: "active_users",
          },
        ],
      },
    ],
  },
  {
    id: "ann_number_comparison",
    label: "ANN Number Comparison",
    items: [
      {
        id: "number-comparison",
        label: "Number Comparison",
        dataURL: "/qa/number-comparison",
        items: [
          {
            id: "ann_number_comparison_summary",
            label: "Number Comparison Summary",
            componentType: "scatterGraph",
            gridSize: 12,
            xAxisTitle: "Percentage Deviation",
            yAxisTitle: "Number of users",
            dataKey: "number_comparison_summary",
          },
          {
            id: "ann_users_with_deviation_more_than_3pct_based_on_organization",
            label: "Users with more than 3% deviation based on Organization",
            componentType: "columnChart",
            gridSize: 12,
            isStacked: true,
            dataKey: "organization_wise_user_count",
          },
          {
            id: "ann_users_with_deviation_more_than_3pct",
            label:
              "Users with more than 3% deviation in Total Sales vs Total Opportunity",
            componentType: "table",
            gridSize: 12,
            formattingOptions: { columnFormatting: true },
            dataKey: "users_with_deviation_more_than_3",
          },
          {
            id: "mi_sight_clients_not_ran_ott",
            label:
              "Clients whose OTT is not run",
            componentType: "table",
            gridSize: 12,
            dataKey: "misight_clients_not_ran_ott",
          },
        ],
      },
    ],
  }],
};

export const HighchartsColors = ["#8087e8", "#1dbfa3", "#f19e53"];
