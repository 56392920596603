import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import { Button, ButtonGroup } from "@mui/material";
import { useSelector } from "react-redux";
import useSWR from "swr";
import MaterialTable from "../../../../components/Table";

import { getClientProfileExclusionsAggregated } from "../../../../utils/api";
import { combineArrays } from "../../../../utils/dataTransformations";
import { ClientProfileContext } from "../ClientProfileContext";
import BulkUploadDialog from "./BulkUploadDialog";
import { generateExclusionTableCallbacks, generateStudySpecificColumns } from "./ColumnsDD";

export default function ExclusionsTableDD() {
  const { clientProfileID, study, apiCallback } = useContext(ClientProfileContext);
  const url = `lms/api/v1/clientprofile/${clientProfileID}/study/${study}/exclusions`;
  const { data, error, mutate } = useSWR(clientProfileID ? url : null, () => getClientProfileExclusionsAggregated(clientProfileID, study));
  const fetchingFirmNames = useSelector((state) => state.application.fetchingBackground.firmNamesDD);
  const firmNames = useSelector((state) => state.formOptions.firmNamesDD);
  const [dataWithFirmNames, setDataWithFirmNames] = useState([]);
  const [bulkUploadDialogOpen, setBulkUploadDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const useRawData = fetchingFirmNames;

  useEffect(() => {
    if (!fetchingFirmNames && data && !error) {
      setDataWithFirmNames(combineArrays(data, firmNames, "firm_crd"));
    }
  }, [firmNames, fetchingFirmNames, data, error]);

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        isLoading={!data || loading}
        columns={generateStudySpecificColumns(study)}
        enableColumnFilterModes
        data={useRawData ? (data ?? []) : (dataWithFirmNames ?? [])}
        title="Broker Exclusions"
        editable={
          generateExclusionTableCallbacks(url, apiCallback, mutate, "deny", study, data, setLoading)
        }
        filterFns={{
          startsWith: (row, id, filterValue) => String(row.getValue(id) ?? "").toLowerCase().startsWith(filterValue.toLowerCase()),
          endsWith: (row, id, filterValue) => String(row.getValue(id) ?? "").toLowerCase().endsWith(filterValue.toLowerCase()),
          contains: (row, id, filterValue) => String(row.getValue(id) ?? "").toLowerCase().includes(filterValue.toLowerCase()),
          equals: (row, id, filterValue) => String(row.getValue(id) ?? "").toLowerCase() === filterValue.toLowerCase(),
        }}
        localization={{
          filterStartsWith: "Starts With",
          filterEndsWith: "Ends With",
          filterContains: "Contains",
          filterEquals: "Equals(Ignore Case)",
        }}
        onEditingRowCancel={({ table }) => { table.setEditingCell(null); table.setEditingRow(null); }}
        renderTopToolbarCustomActions={({ table }) => (
          <ButtonGroup>
            <Button
              variant="contained"
              onClick={() => table.setCreatingRow(true)}
              style={{ marginRight: 10 }}
              endIcon={<AddIcon />}
            >
              Add New
            </Button>
            <Button
              variant="contained"
              onClick={() => setBulkUploadDialogOpen(true)}
              endIcon={<UploadIcon />}
            >
              Bulk Upload
            </Button>
          </ButtonGroup>
        )}
      />
      <BulkUploadDialog open={bulkUploadDialogOpen} setOpen={setBulkUploadDialogOpen} direction="exclusions" />
    </div>
  );
}
