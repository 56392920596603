import { colors } from "@mui/material";

export default {
  mode: "dark",
  themeName: "dark",
  primary: { main: "#81EDC7" },
  secondary: { main: "#AA9FFF" },
  success: {
    main: "#1DBFA3",
    secondary: "#1DBFA3",
  },
  info: { main: "#81EDC7" },
  error: { main: "#F64C4C" },
  edit: { main: "#81EDC7" },
  aborted: { main: "#FA9E25" },
  warning: { main: "#DEBE52" },
  running: { main: "#50759B" },
  queued: { main: "#242E4E" },
  running_queue: { main: "#AC00E6" },
  not_attempted: { main: "#808080" },
  background: {
    default: "#242E4E",
    paper: "#0E1835",
    paperShaded: "#EEF3F7",
    nav: "#242E4E",
    agGridRows: "#242E4E",
  },
  text: {
    shaded: "#97A9BC",
    onBackground: colors.common.white,
    link: "#1DBFA3",
  },
};
